import './AgreementPolicy.scss';
import { useEffect } from 'react';

const PrivacyPolicy: React.FC<any> = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const setPrivacyPolicy = () => {
        return (
            <div className="footer-modal">
                <div className='footer-modal__header'>
                    <h4 className='center'>İSTİFADƏÇİ RAZILAŞMASI VƏ MƏXFİLİK SİYASƏTİ</h4>
                </div>
                <div className='footer-modal__content'>
                    <p className='bold center'>1. Ümumi müddəalar</p>
                    <p>1.1. İstifadəçi razılaşması (bundan sonra "Müqavilə") – Sizinlə “PULPAL” MMC (bundan sonra “Təşkilat”) arasında bağlanmış müqavilədir. Bu Müqavilə, Təşkilat tərəfindən təqdim olunan Tətbiqin və Veb-saytın istifadəsi ilə bağlı Sizinlə Təşkilat arasındakı münasibətləri tənzimləyir.</p>
                    <p>1.2. Bu Müqavilə PulPal-dan istifadəni, ödəniş hesabının açılması, saxlanılması və bağlanılması, ödəniş əməliyyatlarının aparılması, elektron pulun emissiyası, istifadəsi, habelə PulPal vasitəsilə təqdim edilən digər ödəniş xidmətlərindən və funksionallıqlardan istifadə qaydalarını tənzimləyir.</p>
                    <p className='line'></p>
                    <p className='bold center'>2. Müqavilədə istifadə olunan anlayışlar</p>
                    <p>2.1. Təşkilat – “PULPAL” Məhdud Məsuliyyətli Cəmiyyəti;</p>
                    <p>2.2. İstifadəçi və ya Siz – PulPal-da qeydiyyatdan keçmiş fiziki və ya hüquqi şəxs;</p>
                    <p>2.3. PulPal – Təşkilata yaxınlaşmadan onlayn rejimdə hesabları idarə etməyə və müxtəlif  əməliyyatları həyata keçirməyə imkan verən Tətbiq və/və ya Veb-sayt;</p>
                    <p>2.4. Tətbiq – mobil tətbiq “PulPal”, mülkiyyət və intellektual mülkiyyət hüquqları, o cümlədən obyekt və mənbə kodlarına aid hüquqlar “PULPAL” MMC-yə məxsusdur;</p>
                    <p>2.5. Veb-sayt – “PULPAL” MMC-yə məxsus https://pulpal.az/ veb-saytı.</p>
                    <p className='line'></p>
                    <p className='bold center'>3. Haqqımızda</p>
                    <p>3.1. “PULPAL” MMC – Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq təsis edilmiş və öz Nizamnaməsinə əsasən qanunvericiliyə uyğun elektron pul təşkilatı fəaliyyəti ilə məşğul olan hüquqi şəxsdir. Təşkilatın əsas məqsədi mənfəət əldə etməkdir.</p>
                    <p className='bold'>3.2. Təşkilatın tam adı və rekvizitləri:</p>
                    <p>“PULPAL” Məhdud Məsuliyyətli Cəmiyyəti</p>
                    <p>VÖEN: 1006028471</p>
                    <p>Ünvan: AZ1025, Azərbaycan, Bakı şəhəri, Xətai rayonu, Ayaz İsmayılov küçəsi 8a</p>
                    <p>Lisenziya: Elektron pul təşkilatı lisenziyası, qeydiyyat nömrəsi EPT-008, Azərbaycan Respublikasının Mərkəzi Bankı tərəfindən 24 iyul 2024-cü il tarixində verilmişdir</p>
                    <p>Əlaqə vasitələri: +994 77 677 87 77  Müştəri Xidmətləri, https://pulpal.az/</p>
                    <p className='line'></p>
                    <p className='bold center'>4. Qeydiyyat və hesabın açılması</p>
                    <p>4.1. İstifadəçi, Təşkilat tərəfindən müəyyən edilmiş prosedura uyğun olaraq, öz mobil cihazı vasitəsilə Tətbiqi yükləyərək və ya digər elektron vasitələrlə Veb-sayta daxil olaraq PulPal-da qeydiyyatdan keçərək şəxsi kabinet yaradır.</p>
                    <p>4.2. Xidmətlərin göstərilməsi üçün Təşkilat İstifadəçiyə PulPal pul kisəsində ödəniş hesabı açır. Bu Müqavilə ilə İstifadəçi PulPal-a onun üçün ödəniş hesabı açmağa icazə verir.</p>
                    <p>4.3. PulPal müvafiq qanunvericiliyin tələbi nəzərə alınmaqla zödəniş hesabı açıldıqda, o cümlədən, sonrakı mərhələdə İstifadəçi ödəniş hesabına məsafədən daxil olduqda və/və ya hər bir ödəniş əməliyyatı zamanı autentifikasiya və ya gücləndirilmiş İstifadəçi autenfitikasiyası tətbiq edə bilər.</p>
                    <p>4.4. İstifadəçi pul vəsaitlərini ödəniş hesabına ödəniş kartları, ödəniş terminalları və ya digər üsullarla mədaxil edə bilər.</p>
                    <p className='line'></p>
                    <p className='bold center'>5. Elektron pul</p>
                    <p>5.1. PulPal İstifadəçidən elektron pulun emissiyası məqsədilə qəbul etdiyi pul vəsaitləri məbləğində İstifadəçinin ödəniş hesabına dərhal elektron pul emissiya edir.</p>
                    <p>5.2. PulPal bu Müqavilənin və tətbiq edilən qanunvericiliyin tələblərinə uyğun olaraq ödəniş hesabının balansındakı pul vəsaitlərini bloklaşdırmaq, ödəniş əməliyyatlarının məbləğini, sayını və növünü məhdudlaşdırmaq və ya digər zəruri tədbirləri tətbiq etməklə, ödəniş hesabında saxlanılan elektron pulların və ya əməliyyatların məbləğinə birtərəfli olaraq limitlər tətbiq edə bilər.</p>
                    <p>5.3. PulPal qanunvericiliyin tələblərinə uyğun olaraq ödəniş hesabında saxlanılan pul vəsaitlərinin Azərbaycan Respublikasında fəaliyyət göstərən banklar və ya xarici bankların yerli filiallarında açılmış hesabda saxlamaq və ya digər üsullarla təhlükəsizliyinə təminat verir. Bütün hallarda pul vəsaitlərinin təhlükəsizliyinə təminat vermək PulPal-ın vəzifəsidir və bu məqsədlə o, Azərbaycan Respublikasının müvafiq qanunvericiliyi ilə icazə verilən istənilən qanuni təhlükəsizlik üsulunu təkbaşına seçə bilər.</p>
                    <p className='line'></p>
                    <p className='bold center'>6. PulPal-dan istifadə və giriş icazələrinin alınması</p>
                    <p>6.1. PulPal vasitəsilə İstifadəçi köçürmələr, onlayn ödənişlər həyata keçirə, bank hesabları və ödəniş kartlarını idarə edə bilər. Əməliyyatların icrası üçün Təşkilat aşağıdakı giriş icazələrini tələb edə bilər:</p>
                    <p>Təşkilat, mobil cihazınızın bluetooth, coğrafi mövqe, klaviatura kilidi, telefonun vəziyyəti, “sistem xəbərdarlığı” pəncərəsi, təqvim, kamera, kontaktlar, mikrofon, bildirişlər, sensor, SMS mesajları, sosial şəbəkə profilləri, xarici yaddaş və digər funksiyalarına giriş icazəsini tələb edə bilər. Aşağıda göstərilən məqsədlər üçün bəzi icazələr tələb olunur:</p>
                      <ul>
                        <li><strong>Bluetooth</strong> – PulPal vasitəsilə NFC (yaxın məsafədən əlaqə) funksiyasından istifadə üçün;</li>
                        <li><strong>Coğrafi mövqe (Location)</strong> – İstifadəçinin mövqeyinin koordinatlarını müəyyən etmək, tərəfdaş obyektləri və digər xidmətlərdən istifadə üçün;</li>
                        <li><strong>Klaviatura kilidi (Keyguard)</strong> – PIN-kod və digər təhlükəsizlik məlumatlarının qorunması üçün;</li>
                        <li><strong>Telefonun vəziyyəti (Phone state)</strong> – Mobil cihazın bloklanma vəziyyətini müəyyən etmək üçün;</li>
                        <li><strong>“Sistem xəbərdarlığı” pəncərəsi (System alert window)</strong> – Ekran görüntüsü (screenshot) funksiyasını aktivləşdirmək və ya deaktivləşdirmək üçün;</li>
                        <li><strong>Kamera (Camera)</strong> – Kartların, QR-kodların, şəxsiyyət vəsiqəsinin və digər sənədlərin skan edilməsi, QR-kod vasitəsilə ödənişlərin və digər əməliyyatların həyata keçirilməsi üçün;</li>
                        <li><strong>Kontaktlar (Contacts)</strong> – Dostlara köçürmə, çeki bölüşmək, mobil operator xidmətlərindən istifadə (balans artırmaq və s.), üçüncü şəxslərə kod və digər məlumatların ötürülməsi üçün;</li>
                        <li><strong>Xarici yaddaş (External storage)</strong> – Ödənişlərdən sonra çekləri saxlamaq, QR-kod şəkillərini yükləmək, həmçinin elektron kabinet üçün mobil cihazınızın qalereyasından şəkil seçmək üçün;</li>
                        <li><strong>Bloklamadan qorunma (Wake lock)</strong> – Cihazı bloklanmadan saxlamaq (ekranın açıq qalması) üçün;</li>
                        <li><strong>Bildirişlər (Push notifications)</strong> – Hesabınız və Tətbiq ilə bağlı bildirişlər göndərmək üçün. Əgər bu bildirişləri almaq istəmirsinizsə, mobil cihazınızın parametrlərindən bu funksiyanı deaktiv edə bilərsiniz.</li>
                    </ul>
                    <p>Tətbiqdə digər saytlara keçidlər (linklər) ola bilər. Üçüncü tərəfin linkinə klik etdikdə, müvafiq sayta yönləndiriləcəksiniz. Təşkilat kənar saytları idarə etmir, buna görə müvafiq veb-saytların məxfilik siyasətini öyrənməyi tövsiyə edirik. Təşkilat üçüncü tərəf saytların məzmunu, xidmətləri, məxfilik qaydaları və ya tətbiqləri ilə bağlı məsuliyyət daşımır.</p>
                    <p>6.2. PulPal ödəniş hesabının açılması üçün əvvəlcədən və ya ödəniş hesabı açıldıqdan sonra istənilən vaxt İstifadəçini autentifikasiya edə bilər. </p>
                    <p>6.3. Autentifikasiya tədbirləri ödəniş hesabında saxlanılan pulların məbləği və/və ya həyata keçirilən ödəniş əməliyyatlarının məbləği və sayı nəzərə alınmaqla da həyata keçirilə bilər. </p>
                    <p>6.4. PulPal vasitəsilə ödəniş hesabının balansının artırılması zamanı Təşkilat qanunvericiliyə, öz daxili qaydalarına və mülahizələrinə uyğun olaraq autentifikasiya və ya gücləndirilmiş İstifadəçi autentifikasiyası tətbiq edə bilər. </p>
                    <p className='line'></p>
                    <p className='bold center'>7. İstifadəçinin hüquq və öhdəlikləri</p>
                    <p className='bold'>7.1. İstifadəçi aşağıdakı öhdəlikləri öz üzərinə götürür:</p>
                    <p>7.1.1. Mobil cihazın və/və ya SIM-kartın itirilməsi, oğurlanması və ya İstifadəçinin sahibliyindən hər hansı formada çıxması, habelə üçüncü şəxslər tərəfindən qanunsuz istifadəsinin qarşısını almaq üçün bütün mümkün tədbirləri görür.</p>
                    <p>7.1.2. İstifadəçi fərdiləşdirilmiş təhlükəsizlik məlumatlarının üçüncü şəxsin bildiyini aşkar etdikdə dərhal həmin məlumatları PulPal vasitəsilə dəyişdirməli, bu mümkün olmadıqda isə Təşkilata məlumat verməlidir.</p>
                    <p>7.1.3. İstifadəçi anlayır və qəbul edir ki, ödəniş hesabına giriş və istifadə üçün üçüncü şəxslərin istifadəsinə görə tam şəkildə məsuliyyət daşıyır. İstifadəçinin ödəniş hesabına PulPal-dan asılı olmayan hallarda üçüncü şəxslərin girişi nəticəsində İstifadəçiyə dəyən ziyana görə Təşkilat məsuliyyət daşımır.</p>
                    <p>7.1.4. Mobil cihazın və/və ya SIM-kartın itirilməsi, oğurlanması, İstifadəçinin sahibliyindən çıxması və ya üçüncü şəxslər tərəfindən qanunsuz istifadəsi halında dərhal Müştəri Xidmətləri ilə  +994 77 677 87 77 nömrəsinə zəng edərək məlumat verir.</p>
                    <p>7.1.5. İstifadəçi onlayn xidmətlərdən Azərbaycan Respublikasının qanunvericiliyinə, bu Müqaviləyə və Təşkilat tərəfindən müəyyən edilmiş və/və ya gələcəkdə müəyyən ediləcək qayda və tələblərə uyğun istifadə edir.</p>
                    <p>7.1.6. Təşkilatın birtərəfli qaydada bu Müqaviləyə və daxili siyasətinə dəyişiklik etmək hüququ olduğunu qəbul edir. Dəyişikliklər, PulPal Tətbiqinə və/və ya veb sayta yerləşdirildiyi gün İstifadəçi tərəfindən qəbul edilmiş hesab olunur.</p>
                    <p className='bold'>7.2. İstifadəçinin aşağıdakı hüquqları var:</p>
                    <p>7.2.1. PulPal vasitəsilə əməliyyatlar həyata keçirmək;</p>
                    <p>7.2.2. PulPal vasitəsilə həyata keçirilmiş hər hansı əməliyyatla bağlı sorğu və ya iddia olduğu halda, bu barədə +994 77 677 87 77 nömrəsinə zəng edərək Müştəri Xidmətlərini  məlumatlandırmaq. İstifadəçi müvafiq əməliyyatın həyata keçirildiyi tarixdən etibarən 45 gün ərzində sorğu və ya iddia təqdim edə bilər. Bu müddətdən sonra Təşkilata təqdim edilən sorğu və ya iddialar qəbul edilmir və cavablandırılmır.</p>
                    <p className='line'></p>
                    <p className='bold center'>8. Sənədlərin və əməliyyatların hüquqi qüvvəsi</p>
                    <p>8.1. PulPal vasitəsilə həyata keçirilən əməliyyatlar zamanı təsdiq edilmiş elektron sənədlər, İstifadəçi tərəfindən kağız daşıyıcıda şəxsi imzası ilə təsdiq edilmiş müvafiq sənədlərlə eyni hüquqi qüvvəyə malikdir və PulPal vasitəsilə əməliyyatların təsdiqlənməsi İstifadəçinin imzasına bərabər hesab olunur və şəxsi imza ilə eyni hüquqi qüvvəyə malikdir.</p>
                    <p>8.2. İstifadəçi tərəfindən müvafiq hərəkətlərin (təsdiqləmələrin) həyata keçirilməsi, barmaq izi və ya digər biometrik şəxsi məlumatların tanınması funksiyasından istifadə, şəxsi imzanın analoqu kimi qəbul edilir və onunla eyni hüquqi qüvvəyə malikdir.</p>
                    <p>8.3. Hər ödəniş əməliyyatına uyğun olaraq ödəniş sərəncamının icra edilməsi üçün PulPal vasitəsilə İstifadəçidən zəruri məlumatlar tələb edilir.</p>
                    <p>8.4. İstifadəçi tərəfindən bu Müqavilə və/və ya PulPal tərəfindən müəyyən edilmiş bütün şərtlər yerinə yetirildikdə və ödəniş sərəncamı qüsurlu olmadığı təqdirdə Təşkilat ödəniş sərancamını icraya qəbul edir.</p>
                    <p>8.5. Təşkilat ödəniş sərəncamını icraya qəbul etdiyi vaxtdan növbəti iş günündən gec olmayaraq icra edir.</p>
                    <p>8.6. İstifadəçi qəbul edir ki, texniki halın baş verməsi nəticəsində onun hesabında məbləğ olmadığı halda, onun ödəniş sərəncamının icrası PulPal tərəfindən təmin edilə bilər (overdraft). Bu halda ödənişin Təşkilatın vəsaiti hesabına edildiyini nəzərə alaraq, İstifadəçi həmin vəsaitləri Təşkilata 30 (otuz) gündən gec olmayan müddət ərzində geri qaytarmalı olduğunu qəbul edir. PulPal üzərindən İstifadəçiyə daha uzun müddət təqdim edilmədiyi təqdirdə, İstifadəçi həmin vəsaiti və vəsaitdən faydalanmağa görə həmin vəsaitin məbləğindən faizləri (qanunvericiliklə müəyyən edilən qaydada və həddə) Təşkilata geri ödəməlidir.</p>
                    <p>8.7.  İstifadəçi tərəfindən səhv təqdim edilmiş məlumatlar üzrə ödəniş sərəncamının icrası hallarında PulPal vəsaitin qaytarılması üzrə məsuliyyət daşımır. Bununla belə İstifadəçi tərəfindən səhvən təqdim edilmiş məlumatlar əsasında ödəniş əməliyyatı icra edildikdə, İstifadəçi ödəniş əməliyyatı icra edildiyi gündən etibarən 45 gün müddətində Təşkilata bütün sübutedici sənədləri təqdim etməklə ödəniş əməliyyatı üzrə pul vəsaitinin geri qaytarılması ilə bağlı müraciət edə bilər. Təşkilat bu müraciəti əsaslı hesab etdikdə və köçürülmüş vəsaitin qaytarılması mümkün olduqda Müqavilənin 8.8-ci bəndində müəyyən olunmuş qaydaya əməl etməklə İstifadəçiyə həmin vəsaitləri geri qaytara bilər.</p>
                    <p>8.8. Müqavilənin 8.7-ci bəndini nəzərə alaraq İstifadəçi Təşkilata razılıq verir ki, onun ödəniş hesabına digər İstifadəçi tərəfindən əsassız olaraq və səhvən pul vəsaiti mədaxil edildikdə Təşkilat digər İstifadəçiyə həmin vəsaitlərin geri qaytarılması məqsədi ilə müəyyən tədbirlər görə və araşdırmalar apara bilər. Bu kimi hallarda hesabına səhvən vəsait köçürülmüş İstifadəçi vəsaitin geri qaytarılması üzrə Təşkilat ilə əməkdaşlıq edəcəyini qəbul edir. </p>
                    <p>8.9. PulPal  xidmətlərin göstərilməsi üçün xidmət haqqı tətbiq edə və İstifadəçidən tələb edə bilər. Xidmət haqqı tutulduqda və ya valyuta mübadiləsi tətbiq edildikdə həmin xidmət haqqı və ya valyuta məzənnəsi barədə İstifadəçiyə ödəniş sərəncamindan öncə PulPal vasitəsilə məlumat təqdim edilir. İstifadəçi sərəncamı təsdiq etməklə həmin xidmət haqları və ya valyuta məzənnəsinə razılığını bildirmiş olur.</p>
                    <p>8.10. PulPal xidmət haqqını İstifadəçinin ödəniş hesabının balansında olan vəsaitlərdən birtərəfli qaydada tuta bilər.</p>
                    <p>8.11. İstifadəçi başa düşür və qəbul edir ki, PulPal vasitəsilə əməliyyatlar zamanı təsdiq edilmiş elektron sənədlər mübahisələrin həlli zamanı sübut kimi qəbul edilir.</p>
                    <p className='line'></p>
                    <p className='bold center'>9. Bəyanatlar və Zəmanətlər</p>
                    <p>9.1. İstifadəçi bəyan edir və zəmanət verir ki, PulPal-da qeydiyyat zamanı və ya istifadə zamanı daxil etdiyi məlumatlar tam, düzgün və şəxsən ona aiddir. İstifadəçinin PulPal vasitəsilə əməliyyatlar zamanı rekvizitləri, məbləğləri və ya digər məlumatları düzgün daxil etməməsi, həmçinin ona aid olmayan məlumatların daxil edilməsi nəticəsində yaranan risklərə görə məsuliyyət tamamilə İstifadəçinin üzərinə düşür.</p>
                    <p>9.2. İstifadəçi PulPal vasitəsilə əməliyyatlar həyata keçirərkən şəxsi məlumatlarının məxfiliyinin və təhlükəsizliyinin təmin edilməsi, həmçinin üçüncü şəxslərə ötürülməməsi ilə bağlı tədbirlər görməlidir. Təşkilat məlumat mübadiləsinin təhlükəsizliyini təmin etmək üçün tədbirlər görəcək, lakin üçüncü şəxslərin bu məlumatlardan istifadəsi nəticəsində İstifadəçiyə və ya üçüncü şəxslərə dəyən zərərə görə Təşkilat məsuliyyət daşımır.</p>
                    <p>9.3. Təşkilat mobil cihazın və/və ya SIM-kartın itirilməsi, oğurlanması, İstifadəçinin sahibliyindən çıxması və ya üçüncü şəxslər tərəfindən qanunsuz istifadəsi nəticəsində İstifadəçiyə dəyən ziyana görə məsuliyyət daşımır. Bununla yanaşı, İstifadəçi Təşkilata və ya üçüncü şəxslərə dəyən ziyana görə məsuliyyət daşıyır.</p>
                    <p>9.4. Onlayn əməliyyatlar aparılarkən və/və ya sənədlər imzalanarkən yaranan bütün risklərə görə məsuliyyət İstifadəçinin üzərinə düşür. Buna görə İstifadəçi hər hansı əməliyyatı həyata keçirməzdən əvvəl şərtlər və tariflərlə tanış olmalı və yalnız bu şərtləri tam anladıqdan sonra əməliyyatı təsdiqləməlidir.</p>
                    <p>9.5. İstifadəçi PulPal-dan qanunsuz, təhqiramiz, qeyri-etik, dövriyyəsi qadağan (məhdud) olunmuş mallar və xidmətlərin istifadəsi, idman mərclərinin və qumar oyunlarının ödənilməsi üçün istifadə edə bilməz. İstifadəçi bu cür hərəkətlərə görə məsuliyyət daşıyır. Bu cür əməliyyatlar aşkar edildikdə, Təşkilat PulPal vasitəsilə aparılan əməliyyatları bloklaya bilər, həmçinin əməliyyatların növünə və həcminə uyğun olaraq müəyyən limitlər və məhdudiyyətlər tətbiq edə bilər.</p>
                    <p>9.6. Təşkilat, İstifadəçinin qanunsuz və ya qeyri-adi yollarla əldə edilmiş pul və ya digər əmlakın yuyulması və ya terrorizmin maliyyələşdirilməsi ilə bağlı şübhəli əməliyyatlarını aşkar etdikdə, həmçinin Təşkilatla işgüzar münasibətlərin xarakterindən asılı olaraq, PulPal vasitəsilə aparılan əməliyyatları bloklaya, əməliyyatların növünə və həcminə uyğun olaraq müəyyən limitlər və məhdudiyyətlər tətbiq edə bilər.</p>
                    <p>9.7. İstifadəçi PulPal üçün mövcud və ya tələb olunan hər hansı təhlükəsizlik yeniləməsini və ya proqram əlavəsinin quraşdırılmasını qəbul edir.</p>
                    <p className='line'></p>
                    <p className='bold center'>10. ƏL/TMM ilə mübarizə</p>
                    <p>10.1. Tərəflər bəyan edirlər ki, onlar cinayət yolu ilə əldə edilmiş pul vəsaitlərinin leqallaşdırılmasına, terrorçuluğun maliyyələşdirilməsinə və vergidən yayınmaya qarşı mübarizə aparırlar.</p>
                    <p>10.2. İstifadəçi PulPal üzərindən apardığı bütün ödəniş əməliyyatlarında istifadə etdiyi pul vəsaitlərinin mənbəyinin qanuni olmasına görə tam məsuliyyət daşıyır və mənbəyinə dair əsaslı şübhələr olan pul vəsaitlərinin İstifadəçinin ödəniş hesabına qəbulu halında, o cümlədən, ödəniş əməliyyatının özü cinayət, terrorçuluğun maliyyələşdirilməsi və ya digər cinayət əməlləri nəticəsində əldə olunmuş pul vəsaitlərinin leqallaşdırılmasına cəhd məqsədilə aparıldıqda və İstifadəçi bunu bildikdə və ya bilməli olduqda və ya İstifadəçinin aparılan ödəniş əməliyyatının məqsədinə dair əsaslı şübhələri olduqda dərhal Təşkilatı və hüquq mühafizə orqanlarını məlumatlandırmağı öhdəsinə götürür.</p>
                    <p>10.3. PulPal istənilən vaxt İstifadəçinin həyata keçirdiyi ödəniş əməliyyatları ilə bağlı ondan əlavə sənədlər tələb edə bilər.</p>
                    <p className='line'></p>
                    <p className='bold center'>11. Əqli mülkiyyət hüquqları</p>
                    <p>11.1. “PulPal” nişanları Təşkilata məxsus ticarət nişanlarıdır. İstifadəçi Təşkilatın ticarət nişanlarını, loqotipini və ya kommersiya adını şəxsi, təşkilati və ya kommersiya məqsədləri üçün istifadə edə və ya təkrarlaya bilməz.</p>
                    <p>11.2. İstifadəçi və ya üçüncü şəxslərin Tətbiqdən istifadə ilə bağlı iddiaları, o cümlədən üçüncü şəxslərin əqli mülkiyyət hüquqlarının pozulması, məhsul məsuliyyəti və ya qanuni və normativ tələblərin yerinə yetirilməsi ilə bağlı iddialar Təşkilata yönəldilməlidir.</p>
                    <p>11.3. PulPal-a daxil olan səhifələr, təsvirlər, məlumatlar və materiallar, o cümlədən Tətbiqin və ya onun istənilən hissəsinin obyekt və mənbə kodlarına aid müəlliflik hüquqları Təşkilata məxsusdur və ya ona lisenziya əsasında təqdim edilmişdir.</p>
                    <p>11.4. İstifadəçi PulPal-ın hər hansı hissəsinin tərsinə mühəndislik üsulu ilə öyrənilməsi, dəyişdirilməsi, modifikasiyası və ya adaptasiyası, PulPal-ın obyekt kodlarının və ya onun hər hansı hissəsinin mənbə kodlarına və ya genişləndirilmiş proqramlaşdırma dillərinə çevrilməsi, PulPal-ın obyekt kodlarının və ya onun hər hansı hissəsinin mənimsənilməsi, PulPal-ın daxilində və ya onunla bağlı olan müəlliflik hüququna dair bildirişlərə müdaxilə və ya onların çıxarılması (bütün mülkiyyət hüquqları PulPal-a məxsusdur) hərəkətlərini həyata keçirə bilməz. </p>
                    <p className='line'></p>
                    <p className='bold center'>12. Müqavilənin imzalanması, dəyişdirilməsi və ləğv edilməsi qaydaları</p>
                    <p>12.1. Bu Müqavilə İstifadəçinin PulPal-da Qeydiyyatdan keçdiyi, andan bağlanılmış hesab edilir. Müqavilə Tərəflər arasında ləğv edilənədək qüvvədə qalır.</p>
                    <p>12.2. Təşkilat, bu Müqaviləyə edilən dəyişikliklərlə bağlı Sizə PulPal vasitəsilə bildiriş göndərə bilər. Bu halda Siz qeydiyyatınızı (PulPal-dakı hesabınızı) silməklə bu Müqaviləni dəyişikliklərin qüvvəyə minmə tarixindən əvvəl ləğv edə bilərsiniz. Əgər Siz hesabınızı silməsəniz və ya dəyişikliklərlə bağlı bildiriş müddəti bitdikdən sonra PulPal vasitəsilə əməliyyatları davam etdirsəniz, Təşkilat bunu dəyişikliklərin qəbul edilməsi kimi qiymətləndirəcək və dəyişikliklər avtomatik olaraq qüvvəyə minəcəkdir.</p>
                    <p>12.3. Təşkilat aşağıdakı hallardan bir və ya bir neçəsi olduğu halda bu Müqaviləni dərhal ləğv edə bilər:
                    - İstifadəçi bu Müqavilənin şərtlərini və müddəalarını kobud və ya davamlı şəkildə pozduqda;
                    - İstifadəçinin qeyri-qanuni yollarla əldə edilmiş pul və ya digər əmlakın yuyulması, terrorizmin maliyyələşdirilməsi ilə bağlı şübhəli əməliyyatlarının aşkar edilməsi və ya Təşkilatda bu barədə əsaslı şübhələrin olması; </p>
                    <p>- PulPal-dan üçüncü şəxslərin istifadə etdiyi ilə bağlı əsaslı şübhələrin olması;</p>
                    <p>- PulPal-ın mobil cihazınız və ya əməliyyat sisteminiz tərəfindən dəstəklənməməsi və ya əməliyyat sisteminizin istehsalçısı tərəfindən qəbul edilməməsi;</p>
                    <p>-  Müqavilənin davam etməsi zamanı qüvvədə olan qanunvericiliyin, məhkəmə qərarlarının və ya dövlət orqanlarının qoyduğu öhdəliklərin pozulması riski olduğu hallarda;</p>
                    <p>12.4. 12.3-cü maddədə göstərilən halların xarakterinə əsasən Təşkilat, Müqavilənin ləğvi ilə bağlı İstifadəçiyə SMS, elektron poçt və ya digər vasitələrlə məlumat verə bilər.</p>
                    <p>12.5. Siz mobil cihazınızdan Tətbiqi silsəniz belə, gələcəkdə yenidən yükləyərək şəxsi kabinetinizə daxil ola bilərsiniz. Lakin qeydiyyatınızı ləğv etmək üçün Təşkilata müraciət etməlisiniz. Gələcəkdə Təşkilat bu xidməti PulPal vasitəsilə təqdim edə bilər. Əgər qeydiyyatınızı ləğv etsəniz və bu Müqavilə üzrə Təşkilat qarşısında hər hansı öhdəliyiniz yoxdursa, bu Müqavilə ləğv edilmiş hesab olunur.</p>
                    <p className='line'></p>
                    <p className='bold center'>13. Məxfilik Siyasəti</p>
                    <p>
                    13.1. Keyfiyyətli xidmət göstərmək və PulPal-dan istifadəni təmin etmək üçün Sizin Təşkilata məxsus və onunla hüquqi bağlılığı olan proqram təminatı, internet səhifələri, mobil tətbiq vasitəsilə və ya digər formada bizə öz təşübbüsünüzlə razılığınızla təqdim etdiyiniz haqqınızda olan aşağıdakı məlumatları toplaya bilərik:</p>
                    <p>- Ad, soyad, ata adınız;</p>
                    <p>- Doğum tarixi və yeriniz;</p>
                    <p>- Yaşayış / qeydiyyat ünvanınız;</p>
                    <p>- Şəxsiyyətinizi təsdiq edən sənəd barədə məlumatlar;</p>
                    <p>- PulPal-da olan hesablarınız və hesablar üzrə əməliyyatlarınız barədə məlumatlar, o cümlədən həmin hesablar üzərindən mütəmadi əməliyyat etdiyiniz digər xidmət təchizatçılarında olan hesab və ödəniş alətlərini eyniləşdirmək üçün zəruri məlumatlar;</p>
                    <p>- Ödəniş əməliyyatı məlumatlarınız (əməliyyatı eyniləşdirməyə imkan verən nömrə, əməliyyatın tarixi və məbləği, qəbul edən şəxsin, ticarət nöqtəsinin adı və ünvanı və digər məlumatlar);</p>
                    <p>- Ödəniş kartınızı emissiya edən təşkilatın və hesabınıza xidmət göstərən ödəniş xidməti təchizatçısı barədə məlumatlar, ödəmə metodunuz haqqında məlumatlar;</p>
                    <p>- Cihaz məlumatlarınız (telefon, kompüter və ya digər cihazın IP ünvanı; cihazın markası, əməliyyat sisteminin növü, brauzer və digər məlumatları; real vaxt rejimində məkan məlumatları; cihaz barədə Sizi eyniləşdirməyə imkan verən digər məlumatlar (məsələn, cihazın İMEİ nömrəsi, MAC adresi və s.));</p>
                    <p> - Elektron poçt ünvanı;</p>
                    <p>- Telefon nömrəsi;</p>
                    <p>- Biometrik məlumatlar (barmaq izi, üz şəkli, səs yazısı və s.);</p>
                    <p>13.2. PulPal  fərdi məlumatlarınızın Azərbaycan Respublikasının mövcud qanunvericiliyi, Məxfilik Siyasəti və Təşkilatın, habelə Bankın müvafiq informasiya sistemində daxili qaydalara uyğun olaraq işlənməsinə, saxlanmasına, arxivləşdirilməsinə və məhvinə zəmanət verir. Məlumatlarınız qanuna və bu razılığa zidd olan digər məqsədlər üçün istifadə edilməyəcək. </p>
                    <p>13.3. Təşkilat məxfi məlumatları qanunvericilik çərçivəsində istifadəçilərə ən yaxşı xidmətlər və məhsullar təqdim etmək üçün istifadə edə bilər. Təşkilat məlumatları digər kredit təşkilatlarına, xidmət təminatçılarına və ya agentlərə ötürə bilər. Bununla belə, həmin şəxslərin müvafiq məxfilik və təhlükəsizlik standartlarına riayət etməsi Təşkilat tərəfindən təmin edilir.</p>
                    <p>13.4. Təşkilat yalnız qanunvericiliyin icazə verdiyi və ya tələb etdiyi hallarda və müvafiq icra və ya məhkəmə orqanlarına məlumat açıqlaya bilər.</p>
                    <p>13.5. Təşkilat İstifadəçinin şəxsi məlumatlarını aşağıdakı məqsədlər üçün toplayır:</p>
                    <p>- PulPal-da qeydiyyatdan keçməklə təqdim olunan funksionallıqdan istifadə;</p>
                    <p>- PulPal vasitəsilə təklif olunan məhsul çeşidini genişləndirmək;</p>
                    <p>- Təşkilatın təqdim etdiyi xidmətlərin səmərəliliyini ölçmək, bazar araşdırmaları aparmaq;</p>
                    <p>- İstifadəçilərin PulPal tətbiqindəki fəaliyyətlərinin statistikalarını araşdıraraq müştəri məmnuniyyətini artırmaq.</p>
                    <p>13.6. Təşkilat olaraq, yuxarıda qeyd olunanlarla məhdudlaşmadan, Təşkilat adından hərəkət etməyə səlahiyyətli şəxslərə məlumatları açıqlaya və ötürə bilərik. Bu, aşağıdakı məqsədləri əhatə edir:</p>
                    <p>- İstifadəçiyə göstərilən xidmətlərin keyfiyyət və kəmiyyətini artırmaq;</p>
                    <p>- İstifadəçi ilə münasibətləri inkişaf etdirmək, İstifadəçinin şəxsiyyətini müəyyən etmək;</p>
                    <p>- Cinayətlərin və kibercinayətlərin qarşısını almaq və onları aşkar etmək;</p>
                    <p>- Təşkilat daxili idarələrindən və ya ombudsmandan daxil olan sorğular və ya tələblər də daxil olmaqla, məhkəmə və ya icra orqanlarının sorğu və ya tələblərini yerinə yetirmək.</p>
                    <p>13.7. PulPal-dan istifadə etməklə, yuxarıda təsvir olunduğu kimi, məlumatların açıqlanmasına, işlənməsinə, saxlanmasına və ötürülməsinə razılıq verirsiniz.</p>
                    <p>13.8. Bu Müqaviləni imzalayaraq (təsdiqləyərək) Siz razılıq verirsiniz ki, xidmət keyfiyyətinin artırılması, müştəri məmnuniyyətinin yüksəldilməsi, yeni məhsul və xidmətlərin təqdim edilməsi və digər məqsədlər üçün “PULPAL” MMC-yə verilmiş elektron pul təşkilatı lisenziyasına uyğun olaraq, fəaliyyət növlərinə dair əməliyyatlar zamanı Təşkilat Sizin təqdim etdiyiniz və ya digər operatorlardan/alətlərdən əldə etdiyi şəxsi məlumatlarınızı toplaya, işləyə və ötürə bilər. Eyni zamanda Təşkilat , Sizə dair müsbət və mənfi məlumatları kredit bürosuna təqdim edə və ya kredit tarixçənizin yaradılması üçün müvafiq məlumatları kredit bürosundan əldə edə bilər. Bu razılıq Təşkilatın xidmətlərindən istifadə etdiyiniz müddətdə qüvvədə qalır və şəxsi məlumatlar qanunvericilik və Təşkilatın daxili qaydaları ilə müəyyən edilmiş qaydalara əsasən saxlanılır. Siz bu razılığı Təşkilata müraciət edərək geri götürə bilərsiniz.</p>
                    <p>13.9.  Təşkilat, müştərilərə daha səmərəli xidmətlər, məhsullar və təkliflər təqdim etmək üçün müxtəlif növ kukilərdən (cookies) istifadə edə bilər. Kuki, kompüterinizin, mobil telefonunuzun və ya digər elektron cihazınızın yaddaşında saxlanılan kiçik mətn və ya rəqəm formasında olan məlumat faylıdır. Bu fayl sizin veb-səhifələrə etdiyiniz ziyarətlər haqqında məlumat saxlayır. Belə ki, əvvəllər daxil olduğunuz bir veb-səhifəni yenidən ziyarət etdikdə, veb-səhifə sizin məlumatlarınızı yadda saxlayır və  bu, veb-səhifədən daha səmərəli və rahat istifadə etməyiniz üçün vacibdir.</p>
                    <p>13.9.1. Təşkilat tərəfindən istifadə edilən bəzi kukilər istifadəçilərin şəxsi məlumatlarını toplamağa imkan verir. Bu məlumatlar kukilərin hər bir funksiyasının düzgün yerinə yetirilməsi üçün vacibdir. Müvafiq məlumatlardan Təşkilat və aidiyyəti şəxslər istifadə edə bilər.</p>
                    <p>13.9.2. Veb-səhifədən istifadənizi asanlaşdırmaq üçün kuki fayllarının yaradılmasına icazə verdiyinizi qəbul edirsiniz. Bununla belə, siz kukiləri bloklaya, silə və ya məhdudlaşdıra bilərsiniz. Bunun üçün veb-səhifənin və ya brauzerinizin müvafiq parametrlərini tənzimləməlisiniz. Müxtəlif brauzerlərdə fərqli addımlar tələb oluna bilər. Təlimat üçün istifadə etdiyiniz brauzerin rəsmi veb-saytını yoxlamağınızı tövsiyə edirik.</p>
                    <p>13.10. Xidmətlərimizin və məhsullarımızdan istifadənizin təhlükəsizliyini təmin etmək məqsədilə biz təhlükəsizlik tədbirlərimizi təkmilləşdiririk. Bu məqsədlə biz sizi və əməliyyatlarınızı autentifikasiya etmək üçün fərdiləşdirilmiş təhlükəsizlik məlumatları ilə təmin edirik.</p>
                    <p>13.11. Bütün hallarda fərdi məlumatlarınızın üçüncü şəxslərə ötürülməsi Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq həyata keçirilir.</p>
                    <p>13.12. Nəzərə alın ki, biz bu Siyasəti müntəzəm olaraq yeniləyə və ona dəyişikliklər edə bilərik.</p>
                    <p className='line'></p>
                    <p className='bold center'>14. Tətbiq edilən hüquq və mübahisələrin həlli</p>
                    <p>14.1. Bu Müqavilə Azərbaycan Respublikasının qanunvericiliyi ilə tənzimlənir və ona uyğun təfsir olunur.</p>
                    <p>14.2. PulPal İstifadəçi tərəfindən şikayətin daxil olduğu tarixdən 5 (beş) iş günü müddətində müraciətə baxır və nəticəsi barədə İstifadəçini məlumatlandırır. Müraciətə baxılması bu müddətdə mümkün olmadığı halda həmin müddətdən gec olmayaraq şikayətə baxılması müddətinin artırılmasının səbəblərini əsaslandırmaqla şikayətə baxılması müddəti barədə İstifadəçiyə məlumat verir. </p>
                    <p>14.3. Bu Müqavilə üzrə Tərəflər arasında yaranan bütün mübahisələr danışıqlar yolu ilə həll olunur. Danışıqlar nəticəsində tərəflər qarşılıqlı razılaşma əldə etmədikdə mübahisə Azərbaycan Respublikasının səlahiyyətli məhkəmələrində həll edilir.</p>
                    <p className='line'></p>
                    <p className='bold center'>15. Digər müddəalar</p>
                    <p>15.1. Siz bu Müqavilə ilə, İstifadəçi Razılaşması və Məxfilik Siyasəti şərtləri ilə razılaşırsınız.</p>
                    <p>15.2. Bu Müqavilənin hər hansı bəndinin (o cümlədən Əlavələrin) tam və ya qismən etibarsız olması və ya qanunvericiliyə zidd olması müəyyən edildikdə, həmin etibarsız bənd və ya onun etibarsız hissəsi bu Müqavilədən çıxarılmış hesab ediləcək və bu, Müqavilənin digər bəndlərinin etibarsızlığına səbəb olmayacaq.</p>
                    <p>15.3. İstifadəçi bu Müqaviləni imzalamaqla təsdiq edir ki, yetkinlik yaşına çatmışdır.</p>
                    <p>15.4. Bu Müqavilə əsasında Təşkilat öz hüquqlarının hamısını və ya bir hissəsini digər tərəfə ötürə bilər. İstifadəçi isə yalnız Təşkilatın yazılı razılığı ilə öz hüquq və öhdəliklərini digər şəxsə ötürə bilər.</p>
                    <p>15.5. Bu Müqavilə, onun pozulması ilə bağlı və ya ondan irəli gələn bütün iddialar Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq tənzimlənir.</p>
                </div>
            </div>
        )
    }

    return (
        <>{setPrivacyPolicy()}</>
    )
}

export default PrivacyPolicy;